@use '@/styles/utils/mixins.scss' as *;

.checkboxListWrapper {
  display: grid;
  grid-template-columns: repeat(2, minmax(12.5rem, 18.75rem));
  gap: 1rem;
  justify-content: center;

  @include mob() {
    grid-template-columns: 1fr;
    margin: 0 auto;
  }
}

.checkboxItem {
  display: flex;
  padding: 1rem;
  cursor: pointer;

  @include mob() {
    padding: 1rem 0;
  }
}

.checkbox {
  margin-right: 1rem;
  margin-top: 0.25rem;
}

.checkboxContent {
  display: flex;
  flex-direction: column;
}

.checkboxDescription {
  font-size: 0.875rem;
  color: #666;
  margin-top: 0.25rem;
}
